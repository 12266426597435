import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPoolPlayer, ITournamentPlayersPayload} from "data/providers/api/tournament.provider";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {PoolOrderedFields, SortOrder} from "data/enums";

export interface IFilterForm extends HTMLFormElement {
	search: HTMLInputElement;
}

export interface IFilter {
	search: string;
	orderBy: PoolOrderedFields;
	direction: Record<PoolOrderedFields, SortOrder>;
	// avgOrderDirection: SortOrder;
	// ownedOrderDirection: SortOrder;
}

const DEFAULT_FILTER = {
	search: "",
	orderBy: PoolOrderedFields.ProjPts,
	direction: {
		default: SortOrder.ASC,
		lastName: SortOrder.ASC,
		projPts: SortOrder.ASC,
		cuts: SortOrder.ASC,
		top5s: SortOrder.ASC,
		top10s: SortOrder.ASC,
		wins: SortOrder.ASC,
		avgPts: SortOrder.ASC,
		owned: SortOrder.ASC,
		oddsToWin: SortOrder.ASC,
	},
	// avgOrderDirection: SortOrder.ASC,
	// ownedOrderDirection: SortOrder.ASC,
};

export interface IPoolPlayersStore {
	get list(): IPoolPlayer[];
	get isOpen(): boolean;
	get filter(): IFilter;

	toggleHandler: () => void;
	closeHandler: () => void;
	setSearch(value: string): void;
	setOrderBy(value: PoolOrderedFields): void;
	setOrderDirection(value: PoolOrderedFields, order?: SortOrder): void;

	getPoolPlayerById(playerId: number): IPoolPlayer | undefined;
	fetchPoolPlayers(payload: ITournamentPlayersPayload): Promise<void>;
}

@injectable()
export class PoolPlayersStore implements IPoolPlayersStore {
	@observable private _list: IPoolPlayer[] = [];
	@observable private _isOpen: boolean = false;
	@observable private _filter: IFilter = DEFAULT_FILTER;

	get isOpen() {
		return this._isOpen;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get list() {
		return this._list;
	}

	get filter() {
		return this._filter;
	}

	getPoolPlayerById(playerId: number): IPoolPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	toggleHandler = () => {
		this._isOpen = !this._isOpen;
	};

	closeHandler = () => {
		this._isOpen = false;
	};

	@action setSearch(value: string) {
		this._filter.search = value;
	}

	@action setOrderBy(value: PoolOrderedFields) {
		this._filter.orderBy = value;
	}

	@action setOrderDirection(value: PoolOrderedFields, order?: SortOrder) {
		if (order) {
			this._filter.direction[value] = order;
		} else {
			this._filter.direction[value] =
				this._filter.direction[value] === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		}
	}

	@action
	async fetchPoolPlayers(payload: ITournamentPlayersPayload) {
		const {data} = await this._jsonProvider.tournamentPlayers(payload);

		runInAction(() => {
			this._list = data;
		});
	}
}

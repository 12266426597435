import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInvitePageController,
	LeagueInvitePageController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	type ILiveUpdatesStore,
	LiveUpdatesStore,
} from "data/stores/live_updates/live_updates.store";
import {
	type ILeagueCardController,
	LeagueCardController,
} from "views/components/league_card/league_card.controller";
import {
	type ILeaguesSearchController,
	LeaguesSearchController,
} from "views/components/leagues_search/leagues_search.controller";
import {
	type ILeaderboardsProvider,
	LeaderboardsProvider,
} from "data/providers/api/leaderboards.provider";
import {
	type ILeaderboardsStore,
	LeaderboardsStore,
} from "data/stores/leaderboards/leaderboards.store";
import {
	type ILeagueTableController,
	LeagueTableController,
} from "views/pages/league_table/league_table.controller";
import {
	type ILeagueManageController,
	LeagueManageController,
} from "views/pages/league_manage/league_manage.controller";
import {
	type IMobileLinkController,
	MobileLinkController,
} from "views/components/mobile_links/mobile_link.controller";
import {HeaderController, type IHeaderController} from "views/components/header/header.controller";
import {
	DesktopMenuController,
	type IDesktopMenuController,
} from "views/components/desktop_menu/desktop_menu.controller";
import {
	type IModalAuthorizationController,
	ModalAuthorizationController,
} from "views/components/modals/modal_authorization/modal_authorization.controller";
import {
	type IRegistrationFormController,
	RegistrationFormController,
} from "views/components/registration_form/registration_form.controller";
import {
	type ILoginFormController,
	LoginFormController,
} from "views/components/login_form/login_form.controller";
import {
	ForgotPasswordFormController,
	type IForgotPasswordFormController,
} from "views/components/forgot_password_form/forgot_password_form.controller";
import {LandingController, type ILandingController} from "views/pages/landing/landing.controller";
import {
	DeleteAccountController,
	type IDeleteAccountController,
} from "views/components/delete_account/delete_account.controller";
import {type IFooterController, FooterController} from "views/components/footer/footer.controller";
import {
	type IBackdoorController,
	BackdoorController,
} from "views/pages/backdoor/backdoor.controller";
import {type IGameController, GameController} from "views/pages/game/game.controller";
import {type ITeamStore, TeamStore} from "data/stores/team/team.store";
import {
	type ITeamBuilderController,
	TeamBuilderController,
} from "views/components/team_builder/team_builder.controller";
import {
	type IEntryPointsController,
	EntryPointsController,
} from "views/components/entry_points/entry_points.controller";
import {
	type IPlayerSlotController,
	PlayerSlotController,
} from "views/components/player_slot/player_slot.controller";
import {type ITournamentsStore, TournamentsStore} from "data/stores/tournaments/tournaments.store";
import {
	type ITournamentsSelectorController,
	TournamentsSelectorController,
} from "views/components/tournaments_selector/tournaments_selector.controller";
import {type IRosterController, RosterController} from "views/pages/roster/roster.controller";
import {
	type IPlayerPoolController,
	PlayerPoolController,
} from "views/components/player_pool/player_pool.controller";
import {
	type IKeyIndicatorController,
	KeyIndicatorController,
} from "views/components/key_indicator/key_indicator.controller";
import {
	type IPoolPlayersStore,
	PoolPlayersStore,
} from "data/stores/pool_players/pool_players.store";
import {
	type ITournamentPlayersProvider,
	TournamentPlayersProvider,
} from "data/providers/api/tournament.provider";
import {
	type ILeaderboardWidgetController,
	LeaderboardWidgetController,
} from "views/components/leaderboard_widget/leaderboard_widget.controller";
import {
	type IPlayerPoolMobileController,
	PlayerPoolMobileController,
} from "views/components/player_pool_mobile/player_pool_mobile.controller";
import {type ITeamProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {
	type IPlayerSlotRowController,
	PlayerSlotRowController,
} from "views/components/player_slot_row/player_slot_row.controller";
import {
	type ISaveBarController,
	SaveBarController,
} from "views/components/save_bar/save_bar.controller";
import {
	type IModalCaptainController,
	ModalCaptainController,
} from "views/components/modals/modal_captain/modal_captain.controller";
import {
	type IPlayersLeaderboardController,
	PlayersLeaderboardController,
} from "views/components/players_leaderboard/players_leaderboard.controller";
import {
	type IModalSecondaryRegistrationController,
	ModalSecondaryRegistrationController,
} from "views/components/modals/modal_secondary_register/modal_secondary_register.controller";
import {
	type IPromptModalController,
	PromptModalController,
} from "views/components/modals/prompt_modal/prompt_modal.controller";
import {
	type IPlayerModalStore,
	PlayerModalStore,
} from "data/stores/player_modal/player_modal.store";
import {
	type IPlayerModalController,
	PlayerModalController,
} from "views/components/player_modal/player_modal.controller";
import {type ISegmentsStore, SegmentsStore} from "data/stores/segments/segments.store";
import {
	type ILeagueTournamentsModalController,
	LeagueTournamentsModalController,
} from "views/components/league_tournaments_modal/league_tournaments_modal.controller";
import {
	type ISegmentTournamentsListController,
	SegmentTournamentsListController,
} from "views/components/segment_tournaments_list/segment_tournaments_list.controller";
import {
	type ISegmentsAccordionController,
	SegmentsAccordionController,
} from "views/components/segments_accordion/segments_accordion.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/components/league_invite/league_invite.controller";
import {
	type IPlayerUsesController,
	PlayerUsesController,
} from "views/components/player_uses/player_uses.controller";
import {
	type ILeaderboardController,
	LeaderboardController,
} from "views/pages/leaderboard/leaderboard.controller";
import {
	type IModalCompareController,
	ModalCompareController,
} from "views/components/modals/modal_compare/modal_compare.controller";
import {
	type ICompareApiProvider,
	CompareApiProvider,
} from "data/providers/api/compare.api.provider";
import {
	type ITeamScoredBarController,
	TeamScoredBarController,
} from "views/components/team_scored_bar/team_scored_bar.controller";
import {
	type ITournamentController,
	TournamentController,
} from "views/components/modals/modal_compare/tournament/tournament.controller";
import {
	type ILineupRowController,
	LineupRowController,
} from "views/components/modals/modal_compare/tournament/lineup_row/lineup_row.controller";
import {
	type IModalLeagueJoinController,
	ModalLeagueJoinController,
} from "views/components/modals/modal_league_join/modal_league_join.controller";
import {
	type IMyLeaguesWidgetController,
	MyLeaguesWidgetController,
} from "views/components/my_leagues_widget/my_leagues_widget.controller";
import {
	type IPopularPlayersController,
	PopularPlayersController,
} from "views/components/popular_players/popular_players.controller";
import {
	type IExpertTeamsController,
	ExpertTeamsController,
} from "views/components/expert_teams/expert_teams.controller";
import {
	type IPlayerWidgetItemController,
	PlayerWidgetItemController,
} from "views/components/player_widget_item/player_widget_item.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/components/live_score/live_score.controller";
import {type ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {
	type IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {
	type IModalTeamSavedController,
	ModalTeamSavedController,
} from "views/components/modals/modal_team_saved/modal_team_saved.controller";
import {type IWidgetController, WidgetController} from "views/pages/widget/widget.controller";
import {type IAdController, AdController} from "views/components/ad/ad.controller";
import {
	type IRegenerateLeagueController,
	RegenerateLeagueController,
} from "views/pages/regenerate_league/regenerate_league.controller";
import {type ISecretController, SecretController} from "views/pages/secret/secret.controller";
import {
	type IDontLoseTeamModalController,
	DontLoseTeamModalController,
} from "views/components/modals/dont_lose_team_modal/dont_lose_team_modal.controller";
import {
	type IModalAutoSwapController,
	ModalAutoSwapController,
} from "views/components/modals/modal_auto_swap/modal_auto_swap.controller";
import {
	type IPromotionalWidgetController,
	PromotionalWidgetController,
} from "views/pages/promotional_widget/promotional_widget.controller";
import {
	type IPromotionalWidgetStore,
	PromotionalWidgetStore,
} from "data/stores/promotional_widget/promotional_widget.store";
import {
	type IPromotionalWidgetPreviewController,
	PromotionalWidgetPreviewController,
} from "views/pages/promotional_widget_preview/promotional_widget_preview.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<ILeaderboardsProvider>(Bindings.LeaderboardsProvider).to(LeaderboardsProvider);
	bind<ITournamentPlayersProvider>(Bindings.TournamentPlayersProvider).to(
		TournamentPlayersProvider
	);
	bind<ITeamProvider>(Bindings.TeamProvider).to(TeamApiProvider);
	bind<ICompareApiProvider>(Bindings.CompareApiProvider).to(CompareApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILiveUpdatesStore>(Bindings.LiveUpdatesStore).to(LiveUpdatesStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<ILeaderboardsStore>(Bindings.LeaderboardsStore).to(LeaderboardsStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<ITournamentsStore>(Bindings.TournamentsStore).to(TournamentsStore).inSingletonScope();
	bind<IPoolPlayersStore>(Bindings.PoolPlayersStore).to(PoolPlayersStore).inSingletonScope();
	bind<IPlayerModalStore>(Bindings.PlayerModalStore).to(PlayerModalStore).inSingletonScope();
	bind<ISegmentsStore>(Bindings.SegmentsStore).to(SegmentsStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<IPromotionalWidgetStore>(Bindings.PromotionalWidgetStore)
		.to(PromotionalWidgetStore)
		.inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInvitePageController>(Bindings.LeagueInvitePageController).to(
		LeagueInvitePageController
	);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<IModalCaptainController>(Bindings.ModalCaptainController).to(ModalCaptainController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ILeagueCardController>(Bindings.LeagueCardController).to(LeagueCardController);
	bind<ILeaguesSearchController>(Bindings.LeaguesSearchController).to(LeaguesSearchController);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
	bind<ILeagueManageController>(Bindings.LeagueManageController).to(LeagueManageController);
	bind<IMobileLinkController>(Bindings.MobileLinkController).to(MobileLinkController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IDesktopMenuController>(Bindings.DesktopMenuController).to(DesktopMenuController);
	bind<IModalAuthorizationController>(Bindings.ModalAuthorizationController).to(
		ModalAuthorizationController
	);
	bind<IRegistrationFormController>(Bindings.RegistrationFormController).to(
		RegistrationFormController
	);
	bind<ILoginFormController>(Bindings.LoginFormController).to(LoginFormController);
	bind<IForgotPasswordFormController>(Bindings.ForgotPasswordFormController).to(
		ForgotPasswordFormController
	);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IDeleteAccountController>(Bindings.DeleteAccountController).to(DeleteAccountController);
	bind<IFooterController>(Bindings.FooterController).to(FooterController);
	bind<IBackdoorController>(Bindings.BackdoorController).to(BackdoorController);
	bind<IGameController>(Bindings.GameController).to(GameController);
	bind<ITeamBuilderController>(Bindings.TeamBuilderController).to(TeamBuilderController);
	bind<IEntryPointsController>(Bindings.EntryPointsController).to(EntryPointsController);
	bind<IPlayerSlotController>(Bindings.PlayerSlotController).to(PlayerSlotController);
	bind<ITournamentsSelectorController>(Bindings.TournamentsSelectorController).to(
		TournamentsSelectorController
	);
	bind<IRosterController>(Bindings.RosterController).to(RosterController);
	bind<IPlayerPoolController>(Bindings.PlayerPoolController).to(PlayerPoolController);
	bind<IKeyIndicatorController>(Bindings.KeyIndicatorController).to(KeyIndicatorController);
	bind<ILeaderboardWidgetController>(Bindings.LeaderboardWidgetController).to(
		LeaderboardWidgetController
	);
	bind<IPlayerPoolMobileController>(Bindings.PlayerPoolMobileController).to(
		PlayerPoolMobileController
	);
	bind<IPlayerSlotRowController>(Bindings.PlayerSlotRowController).to(PlayerSlotRowController);
	bind<ISaveBarController>(Bindings.SaveBarController).to(SaveBarController);
	bind<IPlayersLeaderboardController>(Bindings.PlayersLeaderboardController).to(
		PlayersLeaderboardController
	);
	bind<IModalSecondaryRegistrationController>(Bindings.ModalSecondaryRegistrationController).to(
		ModalSecondaryRegistrationController
	);
	bind<IPromptModalController>(Bindings.PromptModalController).to(PromptModalController);
	bind<IPlayerModalController>(Bindings.PlayerModalController).to(PlayerModalController);
	bind<ILeagueTournamentsModalController>(Bindings.LeagueTournamentsModalController).to(
		LeagueTournamentsModalController
	);
	bind<ISegmentTournamentsListController>(Bindings.SegmentTournamentsListController).to(
		SegmentTournamentsListController
	);
	bind<ISegmentsAccordionController>(Bindings.SegmentsAccordionController).to(
		SegmentsAccordionController
	);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<IPlayerUsesController>(Bindings.PlayerUsesController).to(PlayerUsesController);
	bind<ILeaderboardController>(Bindings.LeaderboardController).to(LeaderboardController);
	bind<ITeamScoredBarController>(Bindings.TeamScoredBarController).to(TeamScoredBarController);
	bind<IModalCompareController>(Bindings.ModalCompareController).to(ModalCompareController);
	bind<ITournamentController>(Bindings.TournamentController).to(TournamentController);
	bind<ILineupRowController>(Bindings.LineupRowController).to(LineupRowController);
	bind<IModalLeagueJoinController>(Bindings.ModalLeagueJoinController).to(
		ModalLeagueJoinController
	);
	bind<IMyLeaguesWidgetController>(Bindings.MyLeaguesWidgetController).to(
		MyLeaguesWidgetController
	);
	bind<IPopularPlayersController>(Bindings.PopularPlayersController).to(PopularPlayersController);
	bind<IExpertTeamsController>(Bindings.ExpertTeamsController).to(ExpertTeamsController);
	bind<IPlayerWidgetItemController>(Bindings.PlayerWidgetItemController).to(
		PlayerWidgetItemController
	);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<IModalTeamSavedController>(Bindings.ModalTeamSavedController).to(ModalTeamSavedController);
	bind<IWidgetController>(Bindings.WidgetController).to(WidgetController);
	bind<IAdController>(Bindings.AdController).to(AdController);
	bind<IRegenerateLeagueController>(Bindings.RegenerateLeagueController).to(
		RegenerateLeagueController
	);
	bind<ISecretController>(Bindings.SecretController).to(SecretController);
	bind<IDontLoseTeamModalController>(Bindings.DontLoseTeamModalController).to(
		DontLoseTeamModalController
	);
	bind<IModalAutoSwapController>(Bindings.ModalAutoSwapController).to(ModalAutoSwapController);
	bind<IPromotionalWidgetController>(Bindings.PromotionalWidgetController).to(
		PromotionalWidgetController
	);
	bind<IPromotionalWidgetPreviewController>(Bindings.PromotionalWidgetPreviewController).to(
		PromotionalWidgetPreviewController
	);
});

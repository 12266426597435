import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {
	IFilter,
	IFilterForm,
	IPoolPlayersStore,
} from "data/stores/pool_players/pool_players.store";
import type {IPoolPlayer} from "data/providers/api/tournament.provider";
import {ChangeEvent, SyntheticEvent} from "react";
import {chain, deburr, some} from "lodash";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {PlayerStatus, PoolOrderedFields, SortOrder} from "data/enums";

interface IOrderByList {
	orderBy: PoolOrderedFields;
	key: string;
	defaultVal: string;
}

export interface IPlayerPoolMobileController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get filter(): IFilter;
	get players(): IPoolPlayer[];
	get hasSearchValue(): boolean;
	get lineupSize(): number;
	get fullTeam(): number[];
	get isCurrentLineupFull(): boolean;
	get isPickDisabled(): boolean;
	get isOpenSearchFilter(): boolean;
	get isOpenOrderByFilter(): boolean;
	get orderByList(): IOrderByList[];
	get orderBy(): IOrderByList | undefined;

	toggleHandler: () => void;
	closeHandler: () => void;
	handleFormOnChange: (event: ChangeEvent<IFilterForm>) => void;
	handleFormSubmit: (event: SyntheticEvent<IFilterForm>) => void;
	onSelect: (playerId: number) => void;
	onRemovePlayer: (playerId: number) => void;
	openPlayerModal: (playerId: number) => void;
	toggleIsOpenSearchFilter: () => void;
	toggleIsOpenOrderByFilter: () => void;
	setOrderBy: (value: PoolOrderedFields) => void;
	toggleOrderDirection: () => void;
	clearSearchValue: () => void;
}

@injectable()
export class PlayerPoolMobileController implements IPlayerPoolMobileController {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _isOpenSearchFilter = false;
	@observable private _isOpenOrderByFilter = false;

	get isOpen() {
		return this._poolPlayersStore.isOpen;
	}

	get filter() {
		return this._poolPlayersStore.filter;
	}

	get players() {
		// const fullLineup = concat(this._teamStore.lineup, this._teamStore.bench);

		return chain(this._poolPlayersStore.list)
			.filter((player) => player.status !== PlayerStatus.Inactive)
			.filter(this.isListed)
			.orderBy(
				(item) => {
					if (this.filter.orderBy === PoolOrderedFields.Default) {
						return item.status !== PlayerStatus.Withdrawn;
					}

					return item[this.filter.orderBy];
				},
				[this.filter.direction[this.filter.orderBy]]
			)
			.value();
	}

	private isListed = (player: IPoolPlayer) => {
		const {search} = this._poolPlayersStore.filter;
		const fullName = deburr(`${player.firstName} ${player.lastName}`);

		return !search || fullName.toLowerCase().includes(deburr(search).toLowerCase());
	};

	get hasSearchValue() {
		return Boolean(this.filter.search.length);
	}

	get lineupSize() {
		return this._teamStore.lineupSize;
	}

	get fullTeam() {
		return this._teamStore.fullTeam;
	}

	get isCurrentLineupFull() {
		return this._teamStore.isCurrentLineupFull;
	}

	get isPickDisabled() {
		if (this._tournamentsStore.isDisabledByCompleteRound) {
			return true;
		} else {
			return some([
				this._tournamentsStore.isSelectedTournamentDisabled,
				this._tournamentsStore.isDisabledChangeTeam,
				this._teamStore.hasPrevTeam,
			]);
		}
	}

	get isOpenSearchFilter() {
		return this._isOpenSearchFilter;
	}

	get isOpenOrderByFilter() {
		return this._isOpenOrderByFilter;
	}

	get orderByList() {
		return [
			{
				orderBy: PoolOrderedFields.Name,
				key: "player_pool.filters.name",
				defaultVal: "Name",
			},
			{
				orderBy: PoolOrderedFields.ProjPts,
				key: "player_pool.filters.project_pts",
				defaultVal: "Proj Pts",
			},
			{
				orderBy: PoolOrderedFields.Cuts,
				key: "player_pool.filters.cuts",
				defaultVal: "Cuts",
			},
			{
				orderBy: PoolOrderedFields.Top5s,
				key: "player_pool.filters.top_5",
				defaultVal: "T5’s",
			},
			{
				orderBy: PoolOrderedFields.Top10s,
				key: "player_pool.filters.top_10",
				defaultVal: "T10’s",
			},
			{
				orderBy: PoolOrderedFields.Wins,
				key: "player_pool.filters.top_20",
				defaultVal: "T20’s",
			},
			{
				orderBy: PoolOrderedFields.AvgPts,
				key: "player_pool.filters.avg_pts",
				defaultVal: "Avg Pts",
			},
			{
				orderBy: PoolOrderedFields.Owned,
				key: "player_pool.filters.owned",
				defaultVal: "% Owned",
			},
			{
				orderBy: PoolOrderedFields.OddsToWin,
				key: "player_pool.filters.odds_to_win",
				defaultVal: "Odss To Win",
			},
		];
	}

	get orderBy() {
		return this.orderByList.find(({orderBy}) => this.filter.orderBy === orderBy);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PoolPlayersStore) private _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	init() {
		this._disposer = reaction(
			() => this._poolPlayersStore.isOpen,
			(isOpen) => {
				if (isOpen) {
					this._poolPlayersStore.setOrderBy(PoolOrderedFields.Default);
					this._poolPlayersStore.setOrderDirection(
						PoolOrderedFields.Default,
						SortOrder.DESC
					);
					this._poolPlayersStore.setSearch("");
					this._isOpenOrderByFilter = false;
					this._isOpenSearchFilter = false;
				} else {
					this._poolPlayersStore.setOrderBy(PoolOrderedFields.ProjPts);
				}
			}
		);
	}

	toggleHandler = () => {
		this._poolPlayersStore.toggleHandler();
	};

	closeHandler = () => {
		this._poolPlayersStore.closeHandler();
	};

	@action handleFormOnChange = (event: ChangeEvent<IFilterForm>) => {
		const {search} = event.currentTarget;
		this._poolPlayersStore.setSearch(search.value);
	};

	@action handleFormSubmit = (event: SyntheticEvent<IFilterForm>) => {
		event.preventDefault();
	};

	onSelect = (playerId: number) => {
		this._teamStore.addPlayer(playerId);

		if (this._teamStore.isCurrentLineupFull) {
			setTimeout(() => this.closeHandler(), 600);
		}
	};

	onRemovePlayer = (playerId: number) => {
		this._teamStore.removePlayer(playerId);
	};

	openPlayerModal = (playerId: number) => {
		this._playerModalStore.setPlayerId(playerId);
		this._playerModalStore.setPageArea("player_pool");
		this._playerModalStore.openPlayerModal();
	};

	toggleIsOpenSearchFilter = () => {
		this._isOpenSearchFilter = !this._isOpenSearchFilter;
		this._isOpenOrderByFilter = false;
	};

	toggleIsOpenOrderByFilter = () => {
		this._isOpenOrderByFilter = !this._isOpenOrderByFilter;
		this._isOpenSearchFilter = false;
	};

	setOrderBy = (value: PoolOrderedFields) => {
		if (value === this._poolPlayersStore.filter.orderBy) {
			this._poolPlayersStore.setOrderDirection(value);
		} else {
			const direction = value === PoolOrderedFields.Name ? SortOrder.ASC : SortOrder.DESC;
			this._poolPlayersStore.setOrderBy(value);
			this._poolPlayersStore.setOrderDirection(value, direction);
		}
		this._isOpenOrderByFilter = false;
	};

	toggleOrderDirection = () => {
		this._poolPlayersStore.setOrderDirection(this._poolPlayersStore.filter.orderBy);
	};

	clearSearchValue = () => {
		this._poolPlayersStore.setSearch("");
	};

	dispose() {
		this._disposer?.();
	}
}

import {type RefObject} from "react";
import {inject, injectable} from "inversify";
import {SelectChangeEvent} from "@mui/material";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";

interface IPreviewFilters {
	size: "small" | "big";
	tournamentId: string;
	type: string;
}

interface IParams {
	containerRef: RefObject<HTMLDivElement>;
}

export interface IPromotionalWidgetPreviewController extends ViewController<IParams> {
	get scriptElement(): HTMLScriptElement | null;
	get filters(): IPreviewFilters;
	get tournaments(): ITournament[];

	updateFilters: (e: SelectChangeEvent<unknown>) => void;
}

@injectable()
export class PromotionalWidgetPreviewController implements IPromotionalWidgetPreviewController {
	@observable private _containerRef: RefObject<HTMLDivElement> | null = null;
	@observable private _scriptElement: HTMLScriptElement | null = null;
	@observable private _filters: IPreviewFilters = {
		size: "small",
		tournamentId: "0",
		type: "0",
	};

	get scriptElement(): HTMLScriptElement | null {
		return this._scriptElement;
	}

	set scriptElement(value: HTMLScriptElement | null) {
		this._scriptElement = value;
	}

	get filters(): IPreviewFilters {
		return this._filters;
	}

	get tournaments() {
		return this._tournamentsStore.list;
	}

	constructor(@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore) {
		makeAutoObservable(this);
	}

	private updateScriptTag = (): void => {
		if (!this._containerRef?.current) {
			return;
		}

		this._containerRef.current.innerHTML = "";
		const script = document.createElement("script");
		script.src = `${window.location.origin}/promotional_widget.js`;

		if (this.filters) {
			Object.entries(this.filters).forEach(([key, value]) => {
				if (value && value !== "null" && value !== "0") {
					script.dataset[key] = value as string;
				}
			});
		}

		this._containerRef.current.appendChild(script);

		this._scriptElement = script;
	};

	public updateFilters = (e: SelectChangeEvent<unknown>): void => {
		const {name, value} = e.target;
		this._filters = {
			...this.filters,
			[name]: value as string,
		};

		this.updateScriptTag();
	};

	init(params: IParams): void {
		this._containerRef = params.containerRef;

		this.updateScriptTag();
	}
}

import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
	IStaticContentPayload,
} from "data/stores/static_content/static_content.store";
import type {IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import {RoundStatusFeed} from "data/enums";
import {GAME_SLUG} from "data/constants";
import {ITournament} from "data/stores/tournaments/tournaments.store";
import {IPoolPlayer, ITournamentPlayersPayload} from "data/providers/api/tournament.provider";
import {IPlayerRanking} from "data/stores/leaderboards/leaderboards.store";
import {
	IPlayerModalPayload,
	IPlayerModalResponse,
} from "data/stores/player_modal/player_modal.store";
import {ISegment} from "data/stores/segments/segments.store";
import {IWidgetPlayers} from "data/stores/promotional_widget/promotional_widget.store";

export interface ITournamentPlayerStatsPayload {
	tournamentId: number;
	playerId: number;
	endRoundBonusPts: number | null;
}

export interface IExpertPlayer {
	id: number;
	owned: number | null;
}

export interface IExpertTeam {
	displayName: string;
	captainId: number;
	lineup: IExpertPlayer[];
	bench: IExpertPlayer[];
}

export interface ISidebarRanking {
	id: number;
	firstName: string;
	lastName: string;
	headshot: string;
	position: number;
	pts: number | null;
	thru?: number | null;
	tot?: number | null;
}

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	tournaments(): Promise<AxiosResponse<ITournament[]>>;
	rounds(): Promise<AxiosResponse<IRound[]>>;
	players(): Promise<AxiosResponse<IPlayer[]>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;
	segments(): Promise<AxiosResponse<ISegment[]>>;
	tournamentPlayers(params: ITournamentPlayersPayload): Promise<AxiosResponse<IPoolPlayer[]>>;
	tournamentPlayerStats(
		params: ITournamentPlayerStatsPayload
	): Promise<AxiosResponse<IPoolPlayer[]>>;
	tournamentPlayerLeaderboard(
		params: ITournamentPlayersPayload
	): Promise<AxiosResponse<IPlayerRanking[]>>;
	sidebarRanking(): Promise<AxiosResponse<ISidebarRanking[]>>;
	playerModal(params: IPlayerModalPayload): Promise<AxiosResponse<IPlayerModalResponse>>;
	expertTeam(params: ITournamentPlayersPayload): Promise<AxiosResponse<IExpertTeam[]>>;
	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(params: IStaticContentPayload): Promise<AxiosResponse<ICategories>>;
	helpSections(params: IStaticContentPayload): Promise<AxiosResponse<ISections>>;
	helpArticles(params: IStaticContentPayload): Promise<AxiosResponse<IArticles>>;
	widgetPlayers(tournamentId: number): Promise<AxiosResponse<IWidgetPlayers>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>(`countries.json`);
	tournaments = () => this._jsonClient.get<ITournament[]>(`${GAME_SLUG}/tournaments.json`);
	rounds = () => {
		// TODO Uncomment real request and remove fake data
		// return this._jsonClient.get<IRound[]>(`${GAME_SLUG}/rounds.json`)

		const dates = {
			startDate: new Date().toISOString(),
			endDate: new Date().toISOString(),
		};

		return Promise.resolve({
			data: [
				{
					id: 1,
					status: RoundStatusFeed.OFFICIAL,
					...dates,
				},
				{
					id: 2,
					status: RoundStatusFeed.SUSPENDED,
					...dates,
				},
				{
					id: 3,
					status: RoundStatusFeed.NOT_STARTED,
					...dates,
				},
				{
					id: 4,
					status: RoundStatusFeed.GROUPINGS_OFFICIAL,
					...dates,
				},
			],
		}) as Promise<AxiosResponse<IRound[]>>;
	};
	players = () => this._jsonClient.get<IPlayer[]>(`${GAME_SLUG}/players.json`);
	squads = () => this._jsonClient.get<ISquad[]>(`${GAME_SLUG}/squads.json`);
	checksums = () => this._jsonClient.get<IChecksums>(`checksums.json`);
	segments = () => this._jsonClient.get<ISegment[]>(`${GAME_SLUG}/season_segments.json`);
	tournamentPlayers = ({tournamentId}: ITournamentPlayersPayload) =>
		this._jsonClient.get<IPoolPlayer[]>(
			`${GAME_SLUG}/tournament_${tournamentId}_player_pool.json`
		);
	tournamentPlayerStats = ({tournamentId, playerId}: ITournamentPlayerStatsPayload) =>
		this._jsonClient.get<IPoolPlayer[]>(
			`${GAME_SLUG}/tournament_${tournamentId}_player_${playerId}_stats.json`
		);
	tournamentPlayerLeaderboard = ({tournamentId}: ITournamentPlayersPayload) =>
		this._jsonClient.get<IPlayerRanking[]>(
			`${GAME_SLUG}/tournament_${tournamentId}_player_leaderboard.json`
		);
	sidebarRanking = () =>
		this._jsonClient.get<ISidebarRanking[]>(`${GAME_SLUG}/player_sidebar_leaderboard.json`);
	playerModal = ({tournamentId, playerId}: IPlayerModalPayload) =>
		this._jsonClient.get<IPlayerModalResponse>(
			`${GAME_SLUG}/tournament_${tournamentId}_player_${playerId}_stats.json`
		);
	expertTeam = ({tournamentId}: ITournamentPlayersPayload) =>
		this._jsonClient.get<IExpertTeam[]>(
			`${GAME_SLUG}/tournament_${tournamentId}_expert_teams.json`
		);
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`${GAME_SLUG}/locale/${locale}.json`);

	helpCategories = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`, params);
	helpSections = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`, params);
	helpArticles = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`, params);
	widgetPlayers = (tournamentId: number) =>
		this._jsonClient.get<IWidgetPlayers>(
			`${GAME_SLUG}/tournament_${tournamentId}_embeddable_widget.json?t=${Date.now().toString().substring(9)}`
		);
}

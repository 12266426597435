export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInvitePageController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	LiveUpdatesStore: Symbol("LiveUpdatesStore"),
	LeagueCardController: Symbol("LeagueCardController"),
	LeaguesSearchController: Symbol("LeaguesSearchController"),
	LeaderboardsProvider: Symbol("LeaderboardsProvider"),
	LeaderboardsStore: Symbol("LeaderboardsStore"),
	LeagueTableController: Symbol("LeagueTableController"),
	LeagueManageController: Symbol("LeagueManageController"),
	MobileLinkController: Symbol("MobileLinkController"),
	HeaderController: Symbol("HeaderController"),
	DesktopMenuController: Symbol("DesktopMenuController"),
	ModalAuthorizationController: Symbol("ModalAuthorizationController"),
	RegistrationFormController: Symbol("RegistrationFormController"),
	LoginFormController: Symbol("LoginFormController"),
	ForgotPasswordFormController: Symbol("ForgotPasswordFormController"),
	LandingController: Symbol("LandingController"),
	DeleteAccountController: Symbol("DeleteAccountController"),
	FooterController: Symbol("FooterController"),
	BackdoorController: Symbol("BackdoorController"),
	GameController: Symbol("GameController"),
	TeamStore: Symbol("TeamStore"),
	TeamBuilderController: Symbol("TeamBuilderController"),
	EntryPointsController: Symbol("EntryPointsController"),
	PlayerSlotController: Symbol("PlayerSlotController"),
	TournamentsStore: Symbol("TournamentsStore"),
	TournamentsSelectorController: Symbol("TournamentsSelectorController"),
	RosterController: Symbol("RosterController"),
	PlayerPoolController: Symbol("PlayerPoolController"),
	KeyIndicatorController: Symbol("KeyIndicatorController"),
	PoolPlayersStore: Symbol("PoolPlayersStore"),
	TournamentPlayersProvider: Symbol("TournamentPlayersProvider"),
	LeaderboardWidgetController: Symbol("LeaderboardWidgetController"),
	PlayerPoolMobileController: Symbol("PlayerPoolMobileController"),
	TeamProvider: Symbol("TeamProvider"),
	PlayerSlotRowController: Symbol("PlayerSlotRowController"),
	SaveBarController: Symbol("SaveBarController"),
	ModalCaptainController: Symbol("ModalCaptainController"),
	PlayersLeaderboardController: Symbol("PlayersLeaderboardController"),
	ModalSecondaryRegistrationController: Symbol("ModalSecondaryRegistrationController"),
	PromptModalController: Symbol("PromptModalController"),
	PlayerModalStore: Symbol("PlayerModalStore"),
	PlayerModalController: Symbol("PlayerModalController"),
	SegmentsStore: Symbol("SegmentsStore"),
	LeagueTournamentsModalController: Symbol("LeagueTournamentsModalController"),
	SegmentTournamentsListController: Symbol("SegmentTournamentsListController"),
	SegmentsAccordionController: Symbol("SegmentsAccordionController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	PlayerUsesController: Symbol("PlayerUsesController"),
	LeaderboardController: Symbol("LeaderboardController"),
	ModalCompareController: Symbol("ModalCompareController"),
	CompareApiProvider: Symbol("CompareApiProvider"),
	TeamScoredBarController: Symbol("TeamScoredBarController"),
	TournamentController: Symbol("TournamentController"),
	LineupRowController: Symbol("LineupRowController"),
	ModalLeagueJoinController: Symbol("ModalLeagueJoinController"),
	MyLeaguesWidgetController: Symbol("MyLeaguesWidgetController"),
	PopularPlayersController: Symbol("PopularPlayersController"),
	ExpertTeamsController: Symbol("ExpertTeamsController"),
	PlayerWidgetItemController: Symbol("PlayerWidgetItemController"),
	LiveScoreController: Symbol("LiveScoreController"),
	TutorialController: Symbol("TutorialController"),
	TutorialStore: Symbol("TutorialStore"),
	TooltipContentController: Symbol("TooltipContentController"),
	ModalTutorialController: Symbol("ModalTutorialController"),
	ModalTeamSavedController: Symbol("ModalTeamSavedController"),
	WidgetController: Symbol("WidgetController"),
	AdController: Symbol("AdController"),
	RegenerateLeagueController: Symbol("RegenerateLeagueController"),
	SecretController: Symbol("SecretController"),
	DontLoseTeamModalController: Symbol("DontLoseTeamModalController"),
	ModalAutoSwapController: Symbol("ModalAutoSwapController"),
	PromotionalWidgetController: Symbol("PromotionalWidgetController"),
	PromotionalWidgetStore: Symbol("PromotionalWidgetStore"),
	PromotionalWidgetPreviewController: Symbol("PromotionalWidgetPreviewController"),
};

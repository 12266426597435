import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {identity} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {
	IGolfersWithHighestProjPts,
	IMostSelectedGolfers,
	IMostSelectedGolfersAsCaptains,
	IPromotionalWidgetStore,
} from "data/stores/promotional_widget/promotional_widget.store";
import {IApiResponse} from "data/services/http";
import {PromotionalWidgetType, RequestState} from "data/enums";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";

export type IWidgetPlayer = IPlayer &
	IMostSelectedGolfers &
	IMostSelectedGolfersAsCaptains &
	IGolfersWithHighestProjPts;

export interface IPromotionalWidgetController extends ViewController {
	readonly i18n: ILocalizationStore;

	get widgetType(): PromotionalWidgetType;
	get title(): string;
	get players(): IWidgetPlayer[];
	get statTitle(): string;
	get isLoading(): boolean;
	get isDisabled(): boolean;
	get selectedPlayerId(): number;

	clickHandler: (playerId: number) => void;
	getPlayerCN: (playerId: number) => string;
}

@injectable()
export class PromotionalWidgetController implements IPromotionalWidgetController {
	@observable _requestState: RequestState = RequestState.PENDING;
	@observable _selectedPlayerId: number = 0;

	private _titles = {
		[PromotionalWidgetType.ProjectPoints]: {
			key: "promotional_widget.title.project_points",
			value: "Highest Projected Points",
		},
		[PromotionalWidgetType.Captain]: {
			key: "promotional_widget.title.captain",
			value: "Most Selected Captain",
		},
		[PromotionalWidgetType.Ownership]: {
			key: "promotional_widget.title.ownership",
			value: "Highest Ownership",
		},
	};
	private _statTitles = {
		[PromotionalWidgetType.ProjectPoints]: {
			key: "promotional_widget.stats.proj_pts",
			value: "PROJ. PTS",
		},
		[PromotionalWidgetType.Captain]: {
			key: "promotional_widget.stats.stats",
			value: "STATS",
		},
		[PromotionalWidgetType.Ownership]: {
			key: "promotional_widget.stats.selected_by",
			value: "SELECTED BY%",
		},
	};

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get widgetType() {
		const params = new URLSearchParams(window.location.search);
		const type = params.get("type") as PromotionalWidgetType;

		return (
			type ||
			this._promotionalWidgetStore.widgetData?.stage ||
			PromotionalWidgetType.ProjectPoints
		);
	}

	get title() {
		const {key, value} = this._titles[this.widgetType];

		return this.i18n.t(key, value);
	}

	get players() {
		const widgetPlayers = this._promotionalWidgetStore.widgetData?.[this.widgetType] || [];

		return widgetPlayers
			.map(({playerId, ...rest}) => {
				const player = this._playersStore.getPlayerById(playerId);

				if (!player) return null;

				return {
					...player,
					...rest,
				};
			})
			.filter(identity) as IWidgetPlayer[];
	}

	get statTitle() {
		const {key, value} = this._statTitles[this.widgetType];

		return this.i18n.t(key, value);
	}

	get isDisabled() {
		return !this._selectedPlayerId;
	}

	get selectedPlayerId() {
		return this._selectedPlayerId;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.PromotionalWidgetStore)
		private _promotionalWidgetStore: IPromotionalWidgetStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		console.error(error);
	};

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private resizeHandler = (): void => {
		try {
			const height = window.document.getElementById("widget-wrapper")?.scrollHeight;

			window.parent.postMessage(
				JSON.stringify({
					height,
					widgetId: "promotional_widget",
				}),
				"*"
			);
		} catch (e) {
			// ignore
		}
	};

	init() {
		const params = new URLSearchParams(window.location.search);
		const widgetTournamentId = Number(params.get("tournamentId"));

		const tournamentId = widgetTournamentId || this._tournamentsStore.currentTournament?.id;

		if (!tournamentId) return;

		this._promotionalWidgetStore
			.fetchPlayers(tournamentId)
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(this.resizeHandler);

		window.addEventListener("resize", this.resizeHandler);
	}

	clickHandler = (playerId: number) => {
		this._selectedPlayerId = playerId;
	};

	getPlayerCN = (playerId: number) => (playerId === this._selectedPlayerId ? "selected" : "");

	dispose() {
		window.removeEventListener("resize", this.resizeHandler);
	}
}
